.fee-select {
  /* width: 190.94px;
  height: 36px; */
  background-color: #fcecd4;
  font: Nunito;
  weight: 500;
  size: 14px;
  line-height: 19.1px;
  width: 190.94px;
  height: 36px;
  border-radius: 6px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.fees-div {
  background: #ffffff;
  border: 1px solid rgba(4, 4, 21, 0.06);
  box-shadow: 12px 12px 44px rgb(4 4 21 / 14%);
  border-radius: 4px;
  position: absolute;
  top: 35px;
  left: 27px;
  width: 358.17px;
  box-sizing: border-box;
  z-index: 99;
  text-align: left;
  padding: 8px;
  padding-top: 19px;
  padding-bottom: 0px;
}
.fees-div-title {
  font-weight: bold;
}
.single-fee-div {
  /* background-color: #ffab7c; */
  padding: 8px;
  margin-bottom: 10px;
}
.selected-fee-div {
  background-color: #fcecd4;
}
.single-fee-div:hover {
  background-color: #fcecd4;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.single-fee-div .first-line {
  display: flex;
  justify-content: space-between;
}
.single-fee-description {
  color: rgba(4, 4, 21, 0.6);
}
.fees-div p {
  margin: 5px;
}
.withdraw-top {
  position: relative;
  text-align: center;
  padding: 30px 0 0;
  margin: 0 0 5px;
}
.red-text {
  color: red;
}
.withdraw-top h3 {
  font-weight: 600;
  font-size: 20px;
  margin: 0 0 18px;
}

.withdraw-balance p {
  font-weight: 600;
  font-size: 13px;
  color: rgba(4, 4, 21, 0.6);
  margin: 0 0 6px;
}

.withdraw-balance h3 {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
}

.withdraw-balance-exceed-error {
  width: 100%;
  text-align: end;
  margin: 15px 0;
  color: red;
}
.withdraw-balance-exceed-error p {
  margin: 0;
  animation-name: showText;
  animation-duration: 0.3s;
}

@keyframes showText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.withdraw-top .withdraw-balance {
  width: 290px;
  margin: 0 auto;
  padding: 10px 0;
  background: #fff8ed;
  border: 1px dashed rgba(254, 153, 0, 0.22);
  border-radius: 12px;
  margin-bottom: 7px;
}

.exchange-input-fields {
  margin: 0 0 25px;
}

.exchange-input-field-area {
  display: flex;
}

.exchange-rate {
  display: block;
  text-align: center;
  height: 130px;
  margin: 30px 0;
  position: relative;
  left: 22px;
}

.exchange-rate::before {
  content: "";
  width: 0px;
  height: 190px;
  border: 0.5px solid #ffd89c;
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
}

.exchange-top {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  left: -11px;
  top: 0;
}

.exchange-top span {
  display: flex;
}

.exchange-middle {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  left: -5px;
  top: 54px;
}

.exchange-middle span {
  display: flex;
}

.exchange-bottom {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  left: -11px;
  bottom: 0;
}

.exchange-bottom span {
  display: flex;
}

.select-bank-account-area {
  margin: 0 0 40px;
}

.withdraw-button-area {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.withdraw-button-area button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 600;
  font-size: 20px;
  background: #ffd89c;
  color: rgba(4, 4, 21, 0.7);
  width: 100%;
  height: 50px;
  margin: 0 0 30px;
  border-radius: 26px;
  padding: 0 15px;
  cursor: pointer;
  transition: 0.5s;
}

.withdraw-button-area button:hover {
  background: #fe9900;
}

.withdraw-button-area span svg {
  font-size: 20px;
  margin: 5px 4px 0 0;
}

.field-icon {
  width: 98px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffd89c;
  border-radius: 0px 7px 7px 0px;
  border: 1px solid #ffd89c;
}

.field-icon label {
  display: flex;
  font-weight: 700;
  font-size: 16px;
  margin: 0 5px 0 0;
}

.exchange-field {
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
}

.exchange-field input {
  width: 86%;
  height: 54px;
  text-align: left;
  padding: 0px 30px;
  font-weight: 500;
  font-size: 20px;
  border: 1px solid rgba(4, 4, 21, 0.16);
  border-radius: 7px 0px 0px 7px;
}

.exchange-field span {
  position: absolute;
  left: 18px;
}

.select-bank-account-title {
  font-weight: 600;
  font-size: 18px;
  margin: 0 0 23px;
}

.modal-sidebar-bank-account-list {
  display: grid;
  grid-template-columns: auto auto;
  gap: 12px;
  margin: 0 0 15px;
}

.modal-sidebar-bank-info .bank-info {
  width: 163px;
  min-height: 110px;
  padding: 22px 12px;
  background: #fff;
  border: 1px solid rgba(4, 4, 21, 0.18);
}

.modal-sidebar-bank-info .selected {
  border: 2px solid #ffab7c;
  box-shadow: 6px 6px 22px rgba(254, 153, 0, 0.22);
  border-radius: 7px;
}

.modal-sidebar-bank-info .bank-icon-area {
  padding: 12px 15px;
  margin: 0 0 8px;
}

.modal-sidebar-bank-info .bank-info h3 {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
}

.modal-sidebar-bank-info .bank-icon-img {
  width: 22px;
}

.modal-sidebar-bank-info .bank-icon-area span {
  width: 16px;
}

.modal-sidebar-bank-info .bank-info h3 {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  margin: 0 0 6px;
}

.modal-sidebar-bank-info .bank-info h4 {
  font-size: 14px;
}

.modal-sidebar-bank-info .bank-info h5 {
  font-size: 12px;
  margin: 0 0 6px;
}

.modal-sidebar-add-bank-account .add-bank-account {
  width: 163px;
  min-height: 110px;
  padding: 22px 12px;
}

.modal-sidebar-add-bank-account .add-bank-account h3 {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
}

.modal-sidebar-add-bank-account .bank-icon-area {
  padding: 12px 15px;
  margin: 0 0 8px;
}

.modal-sidebar-add-bank-account .bank-icon-img {
  width: 22px;
}

.modal-sidebar-add-bank-account .bank-icon-area span {
  width: 16px;
}
