.note-card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 390px;
  height: 180px;
  background: #ffffff;
  border-radius: 7px;
  padding: 20px;
}

.note-card-title-area {
  display: flex;
  justify-content: space-between;
}

.note-card-title {
  display: flex;
  justify-content: space-between;
}

.note-title {
  margin-left: 12px;
}

.note-title {
  display: flex;
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  padding-left: 10px;
  color: #0666eb;
}
.note-title input {
  width: 110px;
  height: 25px;
  border-radius: 2px;
  margin: 0;
  border: none;
  font-weight: 400;
  font-size: 18px;
  color: #0666eb;
}

.note-card-icon {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  /* padding: 6px 0 0; */
}

.note-card-icon span {
  font-size: 20px;
}

span.note-card-edit {
  color: #fe9900;
  margin-right: 10px;
}

.note-card-text {
  background: rgba(6, 102, 235, 0.06);
  border-radius: 6px;
  padding: 15px;
}

.note-card-text p {
  margin: 0 0 3px;
  font-weight: 400;
  font-size: 13px;
  color: rgba(4, 4, 21, 0.6);
  line-height: 22px;
}

.note-card-text a {
  font-size: 14px;
  font-weight: 600;
  color: #0666eb;
}

.note-card-copy,
.note-card-edit {
  /* top: -10px; */
  font-size: 20px;
  /* background: #e1e8ec; */
  height: 1.5em;
  width: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.note-card-copy:hover,
.note-card-edit:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: #e1e8ec;
}

.note-card-copy:active,
.note-card-edit:active {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  background: #d2dfe7;
}

.tooltip-card {
  position: relative;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
}

.tooltip-card span svg {
  font-size: 15px;
  margin: 2px 2px 0 0;
}

.tooltip-card:hover {
  color: #000;
}

.tooltip-card .tooltiptext-card {
  background-color: rgb(255 239 214);
  box-shadow: 2px 3px 4px #bcbcbc;
  text-align: center;
  font-size: 16px;
  font-weight: 600px;
  border-radius: 6px;
  padding: 5px 13px;
  position: absolute;
  z-index: 1;
  right: -80px;
  top: -6px;
  -webkit-animation: cssAnimation 1.6s forwards;
  animation: cssAnimation 1.6s forwards;
}
