.invoice-link-area {
  padding: 20px 0 0;
  margin-bottom: 5px;
}
.invoice-link {
  margin-bottom: 24px;
  display: block;
  color: blue;
}
.invoice-link:hover {
  color: rgb(1, 1, 213);
}
.send-invoice-link:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: rgb(233, 230, 230);
}
.invoice-link-area h3 {
  font-weight: 600;
  font-size: 22px;
  color: rgba(4, 4, 21, 0.8);
  margin: 0 0 5px;
}

.invoice-link-area h4 {
  font-weight: 500;
  font-size: 16px;
  color: #0666eb;
  margin: 0 0 16px;
}

.send-invoice-link {
  display: flex;
  align-items: center;
  width: 100%;
  background: #f2f2f2;
  border-radius: 7px;
  color: rgba(4, 4, 21, 0.8);
  border: none;
  padding: 15px 18px;
  margin: 0 0 16px;
}

.send-invoice-link span {
  font-weight: 600;
  font-size: 16px;
  color: rgba(4, 4, 21, 0.8);
  margin: 0 0 0 10px;
}

.send-to-icons {
  display: flex;
  gap: 30px;
}

.send-to {
  text-align: center;
}

.send-to-area h5 {
  font-weight: 600;
  font-size: 16px;
  margin: 25px 0 16px;
}

.send-to p {
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  line-height: normal;
}
