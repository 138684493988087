.card-area {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 24px;
  margin-bottom: 24px;
}

.invoice-transactions {
  display: flex;
  gap: 24px;
}
