.item-delete-icon {
  font-size: 20px;
  /* background: #e1e8ec; */
  height: 30px;
  width: 30px;
  display: flex;
  /* color: red; */
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.item-delete-icon:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  /* background: #e1e8ec; */
  color: red;
}

.item-delete-icon:active {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  /* background: #d2dfe7; */
  color: brown;
}
.sidebar-form-items .item-name input {
  font-size: 14px;
}
