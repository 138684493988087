.invoice-area {
  background: #ffffff;
  border-radius: 7px;
  padding: 20px;
  width: 660px;
}

.invoice-top {
  /* border-bottom: 1px solid rgba(4, 4, 21, 0.12); */
  padding: 0 0 13px;
  margin: 0 0 3px;
}

.invoice-title-btn-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.invoice-title h3 {
  font-weight: 600;
  font-size: 22px;
  color: rgba(4, 4, 21, 0.8);
  margin: 0;
}

.invoice-title p {
  font-weight: 400;
  font-size: 14px;
  color: rgba(4, 4, 21, 0.6);
  margin: 0;
}

.btn-invoice {
  border: none;
  font-weight: 600;
  font-size: 15px;
  border-radius: 20px;
  height: 38px;
  padding: 0 17px;
  cursor: pointer;
  background: #ffd89c;
  color: rgba(4, 4, 21, 0.7);
  transition: 0.5s;
}

.btn-invoice:hover {
  color: black;
  background: #fe99007d;
}
.btn-invoice:active {
  color: tomato;
}
.search-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.search {
  width: 460px;
  /* position: relative; */
  display: flex;
  height: 100%;
  background-color: #faf7f7;
  border-radius: 10px;
  justify-content: space-between;
  animation-name: inverseMoveSearchIcon2;
  animation-duration: 0.5s;
}
@keyframes inverseMoveSearchIcon2 {
  0% {
    background-color: white;
    width: 100px;
  }

  100% {
    width: 460px;
  }
}
.search input {
  border: none;
  width: 85%;
  background: #f8f8f8;
  border-radius: 8px;
  height: 50px;
  padding: 0 10px 0 50px;
  font-size: 18px;
  font-size: 14px;
  color: #929eae;
}

.search span {
  display: flex;
  /* position: absolute; */
  /* left: 300px;
  top: 14px; */
  font-size: 24px;
  align-items: center;
  margin-right: 10px;
}
@keyframes inverseMoveSearchIcon {
  from {
    left: 14px;
    position: absolute;
    margin-top: 16px;
  }
  to {
    left: 400px;
  }
}

.search p {
  display: flex;
  margin-left: 10px;
  align-items: center;
}

.search-animate {
  width: 460px;
  position: relative;
  height: 100%;
  background-color: #faf7f7;
  border-radius: 10px;
}

.search-animate input {
  border: none;
  width: 87%;
  background: #f8f8f8;
  border-radius: 8px;
  height: 50px;
  padding: 0 10px 0 50px;
  font-size: 18px;
  font-size: 14px;
  color: #929eae;
}

.search-animate span {
  position: absolute;
  left: 14px;
  top: 14px;
  font-size: 24px;
  animation-name: moveSearchIcon;
  animation-duration: 0.3s;
}

@keyframes moveSearchIcon {
  from {
    left: 300px;
  }
  to {
    left: 14px;
  }
}
.search-animate p {
  margin-left: 10px;
}
a.see-all {
  color: #0666eb;
  font-weight: 500;
}

.invoice-table {
  overflow-y: scroll;
  height: 60vh;
}

.invoice-table table {
  width: 100%;
}

.invoice-table tbody tr {
  border-bottom: 1px solid #ddd;
}

.invoice-table thead {
  padding: 10px 15px;
  height: 42px;
  font-size: 14px;
}
thead::after,
thead::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

thead::before {
  top: 0;
  border-top: 1px solid rgba(4, 4, 21, 0.16);
  margin-top: -0.5px;
}

thead::after {
  bottom: 0;
  border-bottom: 1px solid rgba(4, 4, 21, 0.16);
}
.invoice-table thead {
  position: sticky;
  top: 0px;
  z-index: 100;
  border: none;
  background: #ededed;
  padding: 10px 15px;
  height: 42px;
  font-size: 14px;
}
.invoice-table thead th {
  text-align: left;
  padding: 0 8px;
  font-weight: 600;
  color: rgba(4, 4, 21, 0.4);
}

.invoice-table tbody th,
td {
  font-size: 14px;
  text-align: left;
  display: table-cell;
  font-weight: 600;
  color: rgba(4, 4, 21, 0.6);
  padding: 10px 6px;
}

.tr-color {
  background: rgba(254, 102, 103, 0.03);
}

.invoice-table tbody tr th {
  border-bottom: 1px solid rgba(4, 13, 23, 0.1);
}

.pending {
  display: flex;
  align-items: center;
  color: #0666eb;
  font-weight: 700;
}

.pending::before {
  content: "";
  width: 5px;
  height: 5px;
  border: 1px solid #0666eb;
  border-radius: 50%;
  display: block;
  margin-right: 6px;
  background: #0666eb;
}

.expired {
  display: flex;
  align-items: center;
  color: #ec9714;
  font-weight: 700;
}

.expired::before {
  content: "";
  width: 5px;
  height: 5px;
  border: 1px solid #ec9714;
  border-radius: 50%;
  display: block;
  margin-right: 6px;
  background: #ec9714;
}

.paid {
  display: flex;
  align-items: center;
  color: #59c87f;
  font-weight: 700;
}

.paid::before {
  content: "";
  width: 5px;
  height: 5px;
  border: 1px solid #59c87f;
  border-radius: 50%;
  display: block;
  margin-right: 6px;
  background: #59c87f;
}
