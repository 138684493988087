.documents-section {
  background: rgba(4, 4, 21, 0.03);
  border-radius: 10px;
  margin: 0 0 12px;
}

.documents-upload-btn {
  padding: 0 0 30px;
}

.documents-upload-btn button {
  width: 480px;
  margin: 0 auto 0;
  font-size: 20px;
}
.open-document-box {
  overflow: hidden;
  animation-name: openBox;
  animation-duration: 0.4s;
}

.close-document-box {
  animation: closeBox 1s 1 linear forwards;
  animation-name: closeBox;
  animation-duration: 0.4s;
}
.close-document-box .documents-upload-btn {
  display: none;
}
@keyframes openBox {
  from {
    height: 0px;
  }
  to {
    height: 319px;
  }
}

@keyframes closeBox {
  from {
    opacity: 1;
    height: 319px;
  }
  to {
    height: 0px;
    opacity: 0;
    display: none;
  }
}
