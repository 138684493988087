.settings-wrapper {
  display: flex;
  background: #ffffff;
  border-radius: 7px;
  padding: 20px 35px;
}

.settings-main {
  flex-grow: 1;
  padding-left: 32px;
}

.settings-form-area {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
}
