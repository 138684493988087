.eye-button-div {
  display: inline;
  margin-left: -40px;
  position: absolute;
}

.change-password-form label {
  display: block;
}

.eye-button-div img {
  margin-top: 12px;
}
.eye-button-div:hover {
  cursor: pointer;
}
.eye-icons {
  margin-top: 15px;
  font-size: 20px;
  color: #ababb1;
  stroke: black;
  stroke-width: 5;
}

.change-password-form {
  width: 420px;
}
