/* .no-data-found {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  background-color: #fe6667;
}
.no-data-found p {
  margin: 0;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fe6667;
  font-size: 20px;
} */
.no-data-found {
  width: 100%;
  height: 86%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
