.footer-bar {
  position: fixed;
  bottom: 18px;
  left: 0;
  right: 0;
  width: 485px;
  height: 48px;
  display: flex;
  align-items: center;
  margin: -46px auto 0;
  background: rgba(255, 255, 255, 0.19);
  box-shadow: 22px 22px 80px rgba(4, 4, 21, 0.1);
  backdrop-filter: blur(22px);
  border-radius: 35px;
  padding: 8px;
}

.footer-nav ul {
  margin: 0;
  padding: 0;
}

.footer-nav ul li {
  display: inline-block;
  margin-right: 20px;
  padding: 0;
  list-style: none;
}

.footer-nav ul li:last-child {
  margin-right: 0;
}

.footer-nav a {
  font-weight: 600;
  font-size: 18px;
  color: rgba(4, 4, 21, 0.7);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  border-radius: 24px;
}

.footer-nav span svg {
  font-size: 20px;
  margin: 4px 3px 0 0px;
}

.footer-nav a.active-class {
  height: 48px;
  background: #ffd89c;
  border-radius: 24px;
}
.footer-nav a.active-class:hover {
  color: black;
  background: #ffd89c;
  transition: all 0.3s ease-in-out;
}

.footer-nav a:hover {
  height: 48px;
  background: #ffd89c;
  border-radius: 24px;
  transition: 0.5s;
}

.nav-bg {
  height: 48px;
  color: #fe6667 !important;
  background: rgba(254, 102, 103, 0.06);
  border-radius: 24px;
}
