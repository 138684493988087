.payment-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  background: #ffffff;
  border-radius: 7px;
  padding: 5px;
  height: 210px;
  min-width: 240px;
}

.payment-card p {
  color: rgba(254, 153, 0, 1);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}

.btn-waitlist {
  border: none;
  padding: 0 20px;
  height: 34px;
  border-radius: 17px;
  font-weight: 500;
  font-size: 15px;
  background: #ffd89c;
  color: rgba(4, 4, 21, 0.7);
  transition: 0.5s;
}

.btn-waitlist:hover {
  background: #fe9900;
}

.payment-card-img-div {
  height: 70%;
}
.revo-card {
  width: 100%;
}
/* #textid {
  transition: transform 0.2s;
}

.revo-card:hover #textid {
  transform: scale(1.015);
} */
.revo-card:hover {
  cursor: grab;
}
.revo-card {
  transition: transform 0.2s;
}

.revo-card:hover {
  transform: scale(1.015);
}