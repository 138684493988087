.document-select {
  display: flex;
  justify-content: space-between;
  padding: 18px 26px;
}

.document-select-left h3 {
  font-weight: 700;
  font-size: 22px;
  color: rgba(4, 4, 21, 0.8);
  margin: 0;
}

.document-select-left p {
  font-weight: 400;
  font-size: 14px;
  color: rgba(4, 4, 21, 0.6);
  margin: 0;
}

.upload-btn.settings-btn {
  box-shadow: none;
  padding: 8px 6px;
  font-weight: 500;
}

.document-select-left span {
  font-weight: 500;
  font-size: 18px;
  font-style: italic;
}

.status-pending {
  color: #0666eb;
}

.status-approved {
  color: #49c080;
}

.status-rejected {
  color: #fe6667;
}
