.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: rgba(240, 240, 240, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-sidebar {
  justify-content: end;
}

.modal_content {
  min-width: 380px;
  min-height: 200px;
  position: fixed;
  top: 0;
  right: 0;
  animation-name: moveModal;
  animation-duration: 0.3s;
}
.close_modal {
  animation-name: closeModal;
  animation-duration: 0.2s;
}
@keyframes example {
  0% {
    width: 0%;
    background-color: red;
  }
  25% {
    width: 25%;
    background-color: yellow;
  }
  50% {
    width: 50%;
    background-color: blue;
  }
  75% {
    width: 75%;
    background-color: green;
  }
  100% {
    width: 100%;
    background-color: red;
  }
}
@keyframes example2 {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}
@keyframes moveModal {
  from {
    /* width: 130%; */
    background-color: none !important;
    right: -480px;
  }
  to {
    right: 0px;
  }
}
@keyframes closeModal {
  from {
    /* width: 130%; */
    /* background-color: none !important; */
    right: 0px;
  }
  to {
    right: -480px;
  }
}
@keyframes opcaity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-sidebar-content {
  width: 420px;
  height: 100vh;
  padding: 0 30px;
  background: #fff;
  overflow-y: auto;
}

.modal-sidebar-content .close-icon {
  position: absolute;
  top: 32px;
  font-size: 22px;
  left: 2px;
  cursor: pointer;
}
