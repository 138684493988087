.shimmer {
  margin-bottom: 5px;
  border-radius: 3px;
}
.left-shimmer {
  float: left;
  /* margin-left: 10px; */
}
.line-shimmer {
  margin-left: 18px;
}
.notification-single-shimmer {
  display: flex;
  align-items: center;
}
