.notification-area {
  background: #ffffff;
  border-radius: 7px;
  padding: 20px;
  min-height: 75vh;
}
.notification-main {
  height: 67vh;
  overflow-y: scroll;
}
.notification-main-content {
  display: flex;
  align-items: center;
}

.notification-main::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.notification-main::-webkit-scrollbar-thumb {
  background: #aeabab;
}
.invoice-table::-webkit-scrollbar-track {
  background: #e6e6e6;
}
input.notification-checkbox[type="checkbox" i] {
  width: 16px;
  height: 16px;
  margin: 0px 10px 0 0;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  padding: 0 18px 22px;
  margin: 0 0 8px;
  border-bottom: 1px solid rgba(4, 4, 21, 0.14);
  height: 17px;
}

.notification-right {
  display: flex;
  align-items: center;
}

.unread-delete-area {
  display: flex;
  margin: 0 30px 0 0;
}

.mark-unread {
  display: flex;
  cursor: pointer;
  margin: 8px 30px 0 0;
}

.mark-unread span {
  font-weight: 600;
  font-size: 20px;
  color: #fe9900;
}

.mark-unread img {
  margin: 2px 8px 0 0;
}

.notification-delete {
  display: flex;
  cursor: pointer;
  margin-top: 7px;
}

.notification-delete span {
  font-weight: 600;
  font-size: 20px;
  color: #fe6667;
}

.notification-delete img {
  margin: 2px 8px 0 0;
}

.notification-box h4:hover {
  transition: all 0.3s ease-in-out;
  color: #fe9900 !important;
  cursor: pointer;
}

.all-notification-check {
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.all-notification-check label {
  font-weight: 600;
  font-size: 20px;
  color: rgba(4, 4, 21, 0.8);
}

.notification-box {
  display: flex;
  align-items: center;
  padding: 7px 18px;
  border-radius: 6px;
  justify-content: space-between;
}
.delete-btn-div img:hover {
  cursor: pointer;
}
.notification-box h4 {
  font-weight: 600;
  font-size: 18px;
  color: rgba(4, 4, 21, 0.6);
  margin: 0px;
}

.notification-box p {
  font-weight: 500;
  font-size: 14px;
  color: rgba(4, 4, 21, 0.6);
  margin: 4px 0 0;
}

.notification-active {
  background: #f7f7f8;
}

.show-unread-area {
  display: flex;
  align-items: center;
}

.show-unread-title span {
  font-weight: 600;
  font-size: 20px;
  color: #005cc8;
  margin: 0 20px 0 0;
}

/* switcher */
.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 22px;
}

.switch > input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: #005cc8;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #005cc8;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
