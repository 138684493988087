.invoice-payment-main {
  background: #f3f3f3;
  min-height: 100vh;
  padding: 80px 0 0;
}

.invoice-payment-area {
  max-width: 620px;
  margin: 0 auto;
  padding: 24px 40px;
  background: #ffffff;
  border-radius: 7px;
}

.invoice-payment-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 17px;
}

.invoice-payment-top label {
  font-weight: 700;
  color: rgba(4, 4, 21, 0.8);
}

.view-bank-icon {
  display: flex;
  align-items: center;
}

.view-invoice span {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  color: rgba(4, 4, 21, 0.8);
  margin: 0 0 0 8px;
}

.invoice-payment {
  margin: 0 0 34px;
}

.invoice-payment h2 {
  font-weight: 700;
  font-size: 27px;
  margin: 0 0 6px;
}

.invoice-payment p {
  font-weight: 600;
  color: #2f2929;
  margin: 0;
}

.view-invoice-table > table {
  margin: 0 0 30px;
  border-radius: 5px;
}

.view-invoice-table table tr {
  border-bottom: 1px solid rgba(4, 4, 21, 0.12);
}

.view-invoice-table table > thead th {
  padding: 10px 15px;
}

.view-invoice-table table > tbody td {
  padding: 10px 15px;
}

.invoice-description {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 10px 16px;
  margin: 0 0 10px;
  overflow-y: auto;
  height: 50px;
}

.invoice-description-area {
  padding: 0 15px 12px;
}

.invoice-description h5 {
  font-size: 12px;
  color: rgba(4, 4, 21, 0.5);
  margin: 0 0 8px;
}

.invoice-description p {
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  color: #040415;
}

.payment-method-area {
  margin: 0 0 20px;
}

.payment-method-area h4 {
  font-weight: 500;
  font-size: 15px;
  color: rgba(4, 4, 21, 0.6);
  margin: 10px 0 15px;
}

.payments {
  display: flex;
  gap: 20px;
}

.card-form-area {
  max-width: 453px;
}

.card-field {
  display: flex;
  flex-direction: column;
  margin: 0 0 12px;
}

.card-field label {
  font-weight: 500;
  font-size: 16px;
  color: #040415;
  margin: 0 0 8px;
}

.card-field input {
  border: 1px solid rgba(4, 4, 21, 0.12);
  padding: 12px 16px;
  font-size: 15px;
  font-weight: 500;
  color: rgba(4, 4, 21, 0.5);
  border-radius: 5px;
}

.card-pay-button-area {
  text-align: center;
  margin: 30px 0 15px;
}

.card-pay-button {
  max-width: 398px;
  cursor: pointer;
  border-radius: 26px;
  border: none;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 36px;
  color: rgba(4, 4, 21, 0.7);
  background: #ffd89c;
  transition: 0.5s;
}

.card-pay-button:hover {
  background: #fe9900;
}

.view-invoice-click-area {
  height: 33px;
  margin: auto 0;
  padding: 0px;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  right: 0px !important;
}

.swiper-button-next,
.swiper-button-prev {
  width: 20px !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 18px !important;
}

.shimmer {
  border-radius: 5px;
}

.all-paycard {
  display: flex;
  justify-content: space-between;
}

.payment-method-title-shimmer {
  margin: 10px 0;
}

.view-invoice-box {
  background: #f3f3f3;
  border: 1px solid rgba(4, 4, 21, 0.12);
  border-radius: 5px;
}

.view-invoice {
  background: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  border-radius: 5px;
}

.view-invoice span {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  color: rgba(4, 4, 21, 0.8);
  margin: 0 0 0 8px;
}

.invoice-description {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 10px 16px;
  margin: 0 0 10px;
  overflow-y: auto;
  height: 50px;
}

.invoice-description-area {
  padding: 0 15px 12px;
}

.view-invoice-table {
  overflow: hidden;
  animation-name: openTable;
  animation-duration: 0.4s;
  padding: 22px 15px 0;
}

.close-invoice-table {
  background: #f3f3f3;
  border: 1px solid rgba(4, 4, 21, 0.12);
  border-radius: 5px;
  animation-name: closeTable;
  animation-duration: 0.4s;
}

.close-invoice-icon {
  transform: rotate(0deg);
}

.open-invoice-icon {
  transform: rotate(180deg);
}

@keyframes openTable {
  from {
    height: 0px;
  }
  to {
    height: 360px;
  }
}

@keyframes closeTable {
  from {
    height: 360px;
  }
  to {
    height: 45px;
  }
}
