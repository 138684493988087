.field-password input {
  padding: 20px 14px 14px 52px !important;
}

.field-password .password-eye-icon {
  cursor: pointer;
  font-size: 22px;
  position: absolute;
  right: 12px;
  top: 15px;
  color: rgba(4, 4, 21, 0.4);
}
