.contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 0 0 20px;
}

.contact-info p {
  font-size: 18px;
  margin: 0;
}

.contact-btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: none;
  font-weight: 600;
  font-size: 15px;
  background: #ffd89c;
  color: rgba(4, 4, 21, 0.7);
  border-radius: 19.4359px;
  height: 38px;
  padding: 0 15px;
  cursor: pointer;
  transition: 0.5s;
}

.contact-btn:hover {
  color: black;
  background: #fe99007d;
}
