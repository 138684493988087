.earning-card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 370px;
  height: 180px;
  background: #ffffff;
  border-radius: 7px;
  padding: 20px;
}

.card-amount-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-amount p {
  font-size: 15px;
  margin: 0 0 5px;
  font-weight: 500;
}

.card-amount h3 {
  font-weight: 700;
  font-size: 30px;
  margin: 0;
  color: rgba(4, 4, 21, 0.8);
}

.btn-withdraw {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: none;
  font-weight: 600;
  font-size: 15px;
  background: #ffd89c;
  color: rgba(4, 4, 21, 0.7);
  border-radius: 19.4359px;
  height: 38px;
  padding: 0 15px;
  cursor: pointer;
  transition: 0.5s;
}

.btn-withdraw:hover {
  color: black;
  background: #fe99007d;
}

button.btn-withdraw svg {
  font-size: 20px;
  margin-top: 5px;
}

.pending-draft-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-pending {
  margin-right: 72px;
}

.card-pending p {
  margin: 0 0 5px;
}

.card-pending span {
  display: flex;
  align-items: center;
  color: #0666eb;
  font-weight: 700;
}

.card-pending span::before {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid #0666eb;
  border-radius: 50%;
  display: block;
  margin-right: 8px;
  background: #0666eb;
}

.card-pending h3 {
  font-weight: 600;
  font-size: 20px;
  color: rgba(4, 4, 21, 0.8);
  margin: 0rem;
}

.card-draft p {
  margin: 0 0 5px;
}

.card-draft span {
  display: flex;
  align-items: center;
  color: #ffab7c;
  font-weight: 700;
}

.card-draft span::before {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid #ffab7c;
  border-radius: 50%;
  display: block;
  margin-right: 8px;
  background: #ffab7c;
}

.card-draft h3 {
  font-weight: 600;
  font-size: 20px;
  color: rgba(4, 4, 21, 0.8);
  margin: 0rem;
}
.info-icon {
  height: 22px;
  width: 31px;
  display: inline;
  background: none;
  border: none;
  padding-top: 4px;
}
.info-icon img {
  /* margin: 2px; */
}
.info-icon:hover {
  cursor: pointer;
  padding-top: 6px;
}
.info-icon:active {
  padding-top: 5px;
}