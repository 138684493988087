.info-paragraph-container {
  text-align: left;
  padding: 10px;
  border-radius: 10px;
  margin: 0 0 16px;
  background-color: #f7f9fa;
  font-size: 17px;
}

.total-info-container {
  margin-top: 40px;
}
.total-info-container p {
  text-align: left;
}
.info-pending span::before {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid #fe9900;
  border-radius: 50%;
  display: block;
  margin-right: 8px;
  background: #fe9900;
}
.info-settlement span::before {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid #0666eb;
  border-radius: 50%;
  display: block;
  margin-right: 8px;
  background: #0666eb;
}
.info-total span::before {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid #49c080;
  border-radius: 50%;
  display: block;
  margin-right: 8px;
  background: #49c080;
}
.info-paragraph-container .info-pending span {
  color: #fe9900 !important;
}
.info-paragraph-container .info-total span {
  color: #49c080 !important;
}
.info-paragraph-container .info-settlement span {
  color: #0666eb !important;
}
