.form-button {
  height: 50px;
  font-size: 20px;
  padding: 5px 20px;
  text-align: center;
  border-radius: 26px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  background: #ffd89c;
  color: rgba(4, 4, 21, 0.7);
  transition: 0.5s;
}

.form-button:hover {
  background: #fe9900;
  transition: 0.3s;
}

.form-button:disabled {
  background: rgba(254, 153, 0, 0.17);
  color: #ffffff;
  box-shadow: none;
}

.form-button:disabled:hover {
  background: rgba(254, 153, 0, 0.17);
}
