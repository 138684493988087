.documents-upload-area {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 0 30px;
}

.document-msg {
  padding: 0 26px;
  margin: 0 0 20px;
  font-size: 20px;
}
