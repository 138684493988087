.withraw-top-amount {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 70px 0 27px;
  text-align: left;
}
.receive-top-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 36px 0 9px;
  text-align: left;
}
.receive-top-amount h2 {
  margin-bottom: 10px;
}
.items-header {
  color: rgba(4, 4, 21, 0.4);
  font-weight: 600;
  font-size: 16px !important;
}
.withraw-top-amount h2 {
  font-weight: 700;
  font-size: 36px;
  margin: 0 0 7px;
}

.withraw-top-amount h4 {
  font-size: 22px;
  font-weight: 600;
  color: rgba(4, 4, 21, 0.6);
  margin: 0;
}

.withdraw-status {
  width: 138px;
  height: 46px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  justify-content: center;
  border-radius: 7px;
}

.status-complete {
  background: rgba(128, 206, 121, 0.12);
  color: #62cf8e;
}

.status-hold {
  background: rgba(6, 102, 235, 0.06);
  color: #fecf86;
}
.status-reject {
  background: rgba(6, 102, 235, 0.06);
  color: red;
}
.withdraw-transaction-details-area {
  border-top: 4px solid #e9e9e95c;
  padding: 22px 0 0;
}

.withdraw-transaction-details-area h2 {
  font-weight: 700;
  font-size: 24px;
  margin: 0 0 23px;
}

.withdraw-transaction-details {
  display: flex;
  justify-content: space-between;
  margin: 0 0 26px;
}
.items-details {
  display: flex;
  justify-content: space-between;
  /* margin: 0 0 26px; */
}
.withdraw-transaction-details h3,
.items-details h3 {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
  color: rgba(4, 4, 21, 0.8);
  margin: 0;
}

.withdraw-transaction-desc h3 {
  font-weight: 600;
  font-size: 18px;
  color: rgba(4, 4, 21, 0.8);
  margin: 0 0 7px;
}

.withdraw-transaction-desc p {
  font-weight: 600;
  font-size: 17px;
  color: rgba(4, 4, 21, 0.6);
  margin: 0;
}
.items-details .quantity-price {
  padding: 5px;
  border-radius: 26px;
  background-color: rgba(255, 216, 156, 0.38);
  padding-left: 10.44px;
  padding-right: 10.44px;
  margin-top: 10px;
  margin-bottom: 15px;
}
