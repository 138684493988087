.portfolio-main {
  background: #ffffff;
  border: 1px solid rgba(4, 4, 21, 0.2);
  border-radius: 7px;
  padding: 20px;
}

.portfolio-field-area {
  margin: 0 0 30px;
}

.portfolio-field-area p {
  margin: 0 0 10px;
  font-weight: 500;
  font-size: 18px;
}

.portfolio-field {
  width: 562px;
  margin-right: 20px;
}

.portfolio-field input {
  width: 92%;
  border: 1px solid rgba(4, 4, 21, 0.06);
  border-radius: 26px;
  color: rgba(4, 4, 21, 0.6);
  padding: 16px 23px;
  font-weight: 500;
  font-size: 15px;
}

.portfolio-link-area {
  display: flex;
}
