.portfolio-added-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 0px 0 20px;
  margin: 0 0 20px;
}

.portfolio-left {
  display: flex;
  align-items: center;
}

.portfolio-left span {
  display: flex;
  font-weight: 500;
  font-size: 20px;
  margin-right: 6px;
}

.portfolio-right button {
  display: flex;
  align-items: center;
  background: #ffd89c;
  border-radius: 7px;
  padding: 12px 8px;
  border: none;
  width: 123px;
}
.portfolio-right button:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.801);
}
.portfolio-right span {
  display: flex;
  display: flex;
  font-weight: 500;
  font-size: 20px;
  margin-right: 6px;
  width: 24px;
  height: 24px;
}
/* .portfolio-right img {
  width: 
} */
