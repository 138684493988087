.details-title-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 18px;
}

.details-title-area h3 {
  font-weight: 600;
  font-size: 24px;
  margin: 0;
}

.account-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
}

.account-details h3 {
  font-weight: 600;
  font-size: 18px;
  color: rgba(4, 4, 21, 0.8);
  margin: 0;
}

.account-details h4 {
  font-weight: 500;
  font-size: 18px;
  color: rgba(4, 4, 21, 0.6);
  margin: 0;
}
