.country-select-button {
  width: 100%;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 14px 12px;
  background: #fff;
  border: 1px solid rgba(4, 4, 21, 0.6);
  border-radius: 0.375rem;
  position: relative;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.truncate img {
  border-radius: 0.125rem;
  height: 1rem;
  margin-right: 0.5rem;
  display: inline;
}

.right-arrow-icon {
  padding-right: 0.5rem;
  align-items: center;
  display: flex;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
}

.right-arrow-icon svg {
  width: 1.25rem;
  height: 1.25rem;
  color: rgba(156, 163, 175);
}

.select_country_area {
  list-style: none;
  font-size: 0.875rem;
  box-shadow: 0px 2px 6px #d4d4d4;
  background-color: rgba(255, 255, 255);
  border-radius: 0.375rem;
  width: 460px;
  max-height: 20rem;
  margin-top: 0.25rem;
  z-index: 10;
  position: absolute;
}

.country-search-area {
  padding: 0.5rem 0.75rem;
}

input.country-search-field {
  display: block;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #ddd;
  appearance: none;
  background-color: #fff;
  padding: 0.6rem 0.75rem;
  border-radius: 0.375rem;
}

.selected-icon {
  padding-right: 2rem;
  color: rgba(37, 99, 235);
  padding-right: 2rem;
  display: flex;
  right: 0;
  position: absolute;
}

.selected-icon svg {
  width: 1.25rem;
  height: 1.25rem;
  display: block;
}

.select-country-list {
  box-sizing: border-box;
  overflow: overlay;
  max-height: 14rem;
}

.select-country-list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.select-country-list::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background-color: #d1d5db;
}

.select-country-list::-webkit-scrollbar-track {
  background-color: #f3f4f6;
}

.select-country-list li {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  --tw-text-opacity: 1;
  font-size: 1rem;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  padding-left: 0.75rem;
  padding-right: 2.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
  cursor: default;
  display: flex;
  position: relative;
}

.select-country-list li:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.select-country-list li img {
  border-radius: 0.125rem;
  height: 1rem;
  margin-right: 0.5rem;
  display: inline;
}
