.invoice-full-area {
  background: #ffffff;
  border-radius: 7px;
  padding: 20px;
  height: 80vh;
}

.invoice-full-area .invoice-title-btn-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  align-items: center;
}

.invoice-full-area .invoice-table tbody:before {
  content: "-";
  display: block;
  line-height: 1em;
  color: transparent;
}

.invoice-full-area thead > tr th {
  font-size: 18px;
}

.invoice-full-area thead {
  border-bottom: 1px solid rgba(4, 4, 21, 0.16);
}

.invoice-full-area tbody > tr td {
  font-size: 18px;
}

.single-invoice:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: #f2f4f5;
}
.odd-column {
  background-color: white;
}
