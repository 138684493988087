.sidebar-logo {
  position: relative;
  text-align: center;
  padding: 20px 0 0;
  margin: 0 0 30px;
}

.sidebar-logo h3 {
  font-weight: 600;
  font-size: 24px;
  margin: 0;
  color: rgba(4, 4, 21, 0.8);
}

.sidebar-form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.sidebar-form input::-webkit-input-placeholder {
  opacity: 0.5;
  color: black;
  font-size: 15px;
}
.sidebar-form-field label {
  font-weight: 600;
  font-size: 14px;
  color: rgba(4, 4, 21, 0.6);
  margin-bottom: 6px;
  text-align: left;
}

.sidebar-form-items .item-total .item-total-price {
  padding: 10px 0;
}
.sidebar-form-items label {
  text-align: left;
  margin-left: 15px;
}
.sidebar-form-field > input {
  background: #f9f9f9;
  border: 1px solid rgb(34 34 42 / 8%);
  border-radius: 22px;
  color: rgba(4, 4, 21, 0.8);
  padding: 12px 15px;
  font-weight: 500;
  font-size: 16px;
}

.sidebar-form-field > input:disabled {
  color: rgba(4, 4, 21, 0.3);
}

.sidebar-form-field textarea {
  background: #f9f9f9;
  border: 1px solid rgb(34 34 42 / 8%);
  border-radius: 10px;
  color: rgba(4, 4, 21, 0.8);
  padding: 12px 15px;
  font-weight: 500;
  font-size: 16px;
}

.sidebar-form-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.sidebar-form-items .sidebar-form-field {
  margin-bottom: 12px;
}

.sidebar-form-items input {
  padding: 10px 15px;
}
.sidebar-form-items > .sidebar-form-field {
  margin-bottom: 12px;
}

.sidebar-form-items .item-name {
  width: 145px;
}

.sidebar-form-items .item-qty {
  width: 70px;
}

.sidebar-form-items .item-price {
  width: 85px;
}

.sidebar-form-items .item-total {
  width: 100px;
  text-align: left;
}

.additem-total .additem {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.additem-total .additem span {
  font-weight: 500;
  font-size: 14px;
  color: #0666eb;
}

.additem-total .additem span svg {
  font-size: 18px;
  margin: 4px 3px 0 0;
}

.additem-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 14px;
}

.item-total {
  display: flex;
  font-size: 14px;
}

.item-total span {
  color: rgba(4, 4, 21, 0.4);
  font-weight: 700;
  margin-right: 20px;
}

.item-total strong {
  color: rgba(4, 4, 21, 0.8);
}

.create-link {
  padding: 16px 0 0;
}

.btn-create-link {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-weight: 600;
  font-size: 20px;
  width: 100%;
  height: 50px;
  margin: 0 0 30px;
  border-radius: 26px;
  padding: 0 15px;
  cursor: pointer;
  background: #ffd89c;
  color: rgba(4, 4, 21, 0.7);
  transition: 0.5s;
}

.btn-create-link:hover {
  background: #fe9900;
}
