.header {
  display: flex;
  position: sticky;
  top: 0px;
  width: 1120px;
  z-index: 9;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 12px;
  padding: 5px 10px;
  box-shadow: -6px -6px 16px rgba(4, 4, 21, 0.04),
    10px 10px 16px rgba(4, 4, 21, 0.04);
  margin: 0 auto;

  /* margin-top: 10px; */
}
.header-area {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background: #f3f3f3;
}
.notification p {
  font-size: 10px;
  display: flex;
  position: absolute;
  top: -8px;
  height: 16px;
  width: 16px;
  right: 5px;
  color: white;
  background-color: #fe9900;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.logo {
  width: 148px;
  transition: transform 0.2s;
}

.logo:hover {
  transform: scale(1.05);
}
.notification span {
  font-size: 20px;
  background: #e8eaec;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}
.notification span:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: #d4dfe4;
}

.notification span:active {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  background: #c5d3dc;
}

.right-nav {
  display: flex;
  align-items: center;
}

.user-profile {
  display: flex;
  align-items: center;
}
.user-profile:hover {
  color: rgb(246, 188, 101);
  transition: all 0.3s ease-in-out;
}
.user-profile:active {
  color: #f69400;
}

.profile-image {
  display: flex;
  margin-right: 10px;
  border-radius: 50%;
}

.notification {
  margin-right: 20px;
}

.profile-name-icon {
  display: flex;
  align-items: center;
}

.profile-name {
  font-weight: 600;
  margin-right: 6px;
}

.profile-icon {
  font-size: 20px;
  margin: 6px 0 0;
}
.profile-image img,
.profile-image .shimmer {
  width: 50px;
  border-radius: 50%;
}
.profile-image .hidden {
  display: none;
}
