.settings-btn {
  min-width: 124px;
  background: #ffd89c;
  color: rgba(4, 4, 21, 0.7);
  border: none;
  display: block;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  border-radius: 28px;
  padding: 12px 28px;
  transition: 0.5s;
}

.settings-btn:hover {
  background: #fe9900;
}

.cancel-btn {
  background: none;
  border: 1px solid rgba(4, 4, 21, 0.22);
  color: rgba(4, 4, 21, 0.8);
  margin-left: 20px;
  box-shadow: none;
}
