.bank-info {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f3f3f3;
  border-radius: 10px;
  text-align: center;
}

.bank-info .bank-icon-area span {
  position: absolute;
  right: -6px;
  bottom: 6px;
}

.bank-info h3 {
  color: #040415;
  margin: 0;
}

.bank-info h4 {
  font-weight: 500;
  color: #040415;
  margin: 0;
}

.bank-info h5 {
  font-weight: 500;
  color: rgba(4, 4, 21, 0.6);
  margin: 0;
}
