@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800&display=swap");
/* html {
  background-color: #f3f5f7;
}
#root {
  background-color: #f3f5f7;
  margin-top: 20px;
} */
body {
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
  color: #040415;
  background: #f8f8f8;
  font-size: 16px;
}

button {
  font-family: "Nunito", sans-serif;
}

button:disabled {
  background: rgba(254, 153, 0, 0.17);
  color: #ffffff;
  box-shadow: none;
}

button:disabled:hover {
  background: rgba(254, 153, 0, 0.17);
}

ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #040415;
}

img {
  max-width: 100%;
}

h1,
h2 {
  margin: 0;
}

input:focus-visible {
  outline-offset: 0;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea:focus-visible {
  outline-offset: 0;
  outline: none;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
}

.main ::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

.main ::-webkit-scrollbar-thumb {
  background: #aeabab;
}

.main ::-webkit-scrollbar-track {
  background: #e6e6e6;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
}

.expiration-cvc {
  display: flex;
  gap: 20px;
}

.datePicker input {
  /* height: 90%; */
}

/* tolltip start */
.tooltip {
  position: relative;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
}

.tooltip span svg {
  font-size: 15px;
  margin: 2px 2px 0 0;
}

.tooltip:hover {
  color: #000;
}

.tooltip .tooltiptext {
  background-color: rgb(255 239 214);
  color: rgba(4, 4, 21, 0.8);
  box-shadow: 2px 3px 4px #bcbcbc;
  text-align: center;
  border-radius: 6px;
  padding: 5px 13px;
  position: absolute;
  z-index: 1;
  right: -80px;
  top: -6px;
  -webkit-animation: cssAnimation 1.6s forwards;
  animation: cssAnimation 1.6s forwards;
}

.single-invoice:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: #f2f4f5;
}

@keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* tolltip end */

/* Small devices (Phones) */
@media only screen and (max-width: 767px) {
  .expiration-cvc {
    display: block;
  }

  .invoice-payment-top {
    display: block;
  }
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* X-Large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
