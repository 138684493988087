.portfolio-shimmer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
}

.portfolio-shimmer .circle {
  border-radius: 50%;
  margin-right: 10px;
}
.portfolio-shimmer .float-right {
  float: right;
}
.portfolio-shimmer .left-contents {
  display: flex;
  align-items: center;
}
