.full-transaction {
  background: #ffffff;
  border-radius: 7px;
  padding: 20px;
  height: 80vh;
}

.full-transaction-data {
  display: flex;
  align-items: center;
}

.full-transaction .swap-btn-area {
  width: 285px;
}

.full-transaction .swap-btn-area .swap-btn {
  font-size: 18px;
  padding: 8px 20px;
}

.full-transaction .transaction-select select {
  font-size: 16px;
  padding: 8px 20px;
}

.full-transaction .swap-btn-area button.swap-btn span svg {
  font-size: 17px;
}

.full-transaction .transaction-title h3 {
  font-size: 26px;
}

.full-transaction-data .download-icon span {
  width: 36px;
  height: 36px;
  background: rgba(6, 102, 235, 0.09);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-transaction-data .transaction-data-title h4 {
  font-size: 18px;
}

.transaction-selct-area {
  display: flex;
  align-items: center;
}

.transaction-selct-area .transaction-title {
  margin-right: 20px;
}

.transaction-table table {
  width: 100%;
}

/* .transaction-table thead {
  border-top: 1px solid rgba(4, 4, 21, 0.16);
  border-bottom: 1px solid rgba(4, 4, 21, 0.16);
} */

.transaction-table thead {
  padding: 10px 15px;
  height: 42px;
  font-size: 14px;
}

.full-transaction .transaction-table tbody:before {
  content: "-";
  display: block;
  line-height: 1em;
  color: transparent;
}

.transaction-table thead th {
  text-align: left;
  padding: 0 8px;
  font-weight: 600;
  color: rgba(4, 4, 21, 0.4);
}

.transaction-table thead > tr th {
  font-size: 18px;
}

.transaction-table tbody > tr td {
  font-size: 18px;
}

.transaction-table .transaction-amount span {
  font-size: 18px;
  font-weight: 600;
}

.tr-color {
  background: rgba(254, 102, 103, 0.03);
}

.transaction-table tbody tr th {
  border-bottom: 1px solid rgba(4, 13, 23, 0.1);
}
.single-transaction:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: #f2f4f5;
}
.single-transaction {
}
.odd-column {
  background-color: rgba(247, 247, 248, 0.6);
}
.no_transactions-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.statement-datepicker {
  /* width: 100px; */
  margin-right: 20px;
}
.statement-wrapper {
  overflow-y: scroll;
  height: 64vh;
}