.withdraw-message {
  text-align: center;
  padding: 100px 0 0;
}

.withdraw-message h3 {
  font-weight: 700;
  font-size: 26px;
  color: #fe6667;
  margin: 0;
}

.withdraw-message h4 {
  font-weight: 700;
  font-size: 18px;
  color: rgba(4, 4, 21, 0.8);
  margin: 6px 0 8px;
}

.withdraw-message p {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: rgba(4, 4, 21, 0.6);
  margin: 0;
}
