.modal.delete-modal {
  background: rgba(0, 0, 0, 0.14);
}

.delete-bank-account {
  width: 443px;
  position: relative;
  bottom: -300px;
  right: 0px;
  border-radius: 7px;
  background: #fff;
}

.delete-bank-account .close-icon {
  position: absolute;
  font-size: 22px;
  top: 16px;
  right: 22px;
  cursor: pointer;
}

.delete-bank-account h2 {
  font-weight: 500;
  font-size: 20px;
  border-bottom: 1px solid rgba(4, 4, 21, 0.14);
  padding: 16px 20px;
  margin: 0 0 17px;
}

.delete-bank-account p {
  font-weight: 500;
  font-size: 18px;
  color: rgba(4, 4, 21, 0.6);
  border-bottom: 1px solid rgba(4, 4, 21, 0.14);
  padding: 0px 20px 30px;
  margin: 0;
}

.delete-bank-account-btn {
  display: flex;
  justify-content: end;
  padding: 20px 0 25px 0;
  margin: 0 22px 0 0;
}
