.transaction-main {
  background: #ffffff;
  border-radius: 7px;
  padding: 17px;
  width: 400px;
}

.transaction-wrapper {
  overflow-y: scroll;
  height: 64vh;
}
.transaction-status-complete {
  /* background: rgba(128, 206, 121, 0.12); */
  color: #62cf8e;
}
.transaction-status-settlement {
  /* background: rgba(128, 206, 121, 0.12); */
  color: #0666eb;
}
.transaction-status-hold {
  /* background: rgba(6, 102, 235, 0.06); */
  color: #fecf86;
}
.transaction-status-reject {
  /* background: rgba(6, 102, 235, 0.06); */
  color: red;
}
.transaction-status {
  font-weight: 600;
  font-size: 14px;
}
.transaction-title-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.transaction-title h3 {
  font-weight: 600;
  font-size: 22px;
  color: rgba(4, 4, 21, 0.8);
  margin: 0;
}

.transaction-title p {
  font-weight: 400;
  font-size: 14px;
  color: rgba(4, 4, 21, 0.6);
  margin: 0;
}

.select-swap-btn-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.transaction-select select {
  padding: 0 10px;
  height: 36.38px;
  background: rgba(4, 4, 21, 0.04);
  border: 1px solid rgba(4, 4, 21, 0.06);
  border-radius: 18.1895px;
}

.swap-btn-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  background: #f4f8fe;
  border-radius: 32px;
  padding: 5px 5px;
}

.swap-btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: none;
  background: none;
  font-weight: 500;
  font-size: 14.5px;
  color: rgba(4, 4, 21, 0.8);
  border-radius: 32px;
  padding: 6px 10px;
  cursor: pointer;
}

.transaction-btn {
  color: #fe9900;
  background: #ffffff;
  transition: all 0.4s ease;
}

button.swap-btn span svg {
  margin: 3px 3px 0 0;
  font-size: 14px;
}

.transaction-data-area {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(4, 4, 21, 0.08);
  padding: 10px 10px;
}
.transaction-data-area:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: #f2f4f5;
}

.transaction-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-data .download-icon span {
  width: 36px;
  height: 36px;
  background: rgba(6, 102, 235, 0.09);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transaction-data-title h4 {
  font-weight: 600;
  font-size: 15px;
  color: #040415;
  margin: 0px;
}

.transaction-data-title p {
  font-weight: 600;
  font-size: 12px;
  color: rgba(4, 4, 21, 0.4);
  margin: 0;
}

.download-icon {
  margin-right: 12px;
}
.transaction-amount {
  text-align: right;
}
.transaction-amount p {
  margin: 0;
}
.transaction-amount span {
  font-weight: 700;
  font-size: 15px;
  color: #49c080;
}
.odd-column {
  background-color: rgba(247, 247, 248, 0.6);
}
