.form-input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.regi-phone-number {
  display: block;
  font-weight: 600;
  font-size: 18px;
  color: rgb(0 0 0 / 60%);
  margin-bottom: 13px;
}

.form-input-field input {
  background: rgba(4, 4, 21, 0.04);
  border: 1px solid rgba(4, 4, 21, 0.06);
  border-radius: 26px;
  color: rgba(4, 4, 21, 0.8);
  padding: 16px 23px;
  font-weight: 500;
  font-size: 16px;
}

.form-input-field textarea {
  background: rgba(4, 4, 21, 0.04);
  border: 1px solid rgba(4, 4, 21, 0.06);
  border-radius: 70px;
  color: rgba(4, 4, 21, 0.8);
  padding: 16px 23px;
  font-weight: 500;
  font-size: 16px;
}

.form-input-field input,
select:focus-visible {
  outline-offset: 0;
  outline: none;
}

.form-button-area {
  margin-top: 50px;
}

.form-input-field select {
  background: rgba(4, 4, 21, 0.04);
  border: 1px solid rgba(4, 4, 21, 0.06);
  border-radius: 26px;
  color: rgba(4, 4, 21, 0.8);
  padding: 12px 23px;
  font-weight: 500;
  font-size: 16px;
}

.form-input-field select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

.form-checkbox {
  display: flex;
}

.form-checkbox input {
  margin: 0px 10px 0 0;
}
