.add-bank-account {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px dashed rgba(4, 4, 21, 0.18);
  border-radius: 10px;
}

.add-bank-account .bank-icon-area span.add-bank-icon {
  position: absolute;
  right: -6px;
  top: 6px;
}

.add-bank-account h3 {
  font-weight: 700;
  text-align: center;
  margin: 0;
}
