.settings-title {
  margin: 0 0 28px;
}

.settings-title h3 {
  font-weight: 600;
  font-size: 24px;
  margin: 0;
}

.settings-title p {
  font-weight: 500;
  font-size: 18px;
  color: rgba(4, 4, 21, 0.6);
  margin: 7px 0 0;
}
