.settings-bank-info .bank-info {
  min-height: 220px;
  padding: 10px 15px;
  border: 1px solid #f3f3f3;
}

.settings-bank-info .bank-icon-area {
  padding: 22px 25px;
  margin: 0 0 18px;
}

.settings-bank-info .selected {
  border: 1px solid #fe6667;
}

.settings-bank-info .bank-info h3 {
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
  margin: 0 0 6px;
}

.settings-bank-info .bank-info h4 {
  font-size: 16px;
  text-transform: uppercase;
}

.settings-bank-info .bank-info h5 {
  font-size: 14px;
  margin: 0 0 6px;
}

.settings-bank-info .bank-icon-img {
  width: 42px;
}

.settings-bank-info .bank-icon-area span {
  width: 30px;
}

.settings-add-bank-account .add-bank-account {
  min-height: 220px;
  padding: 10px 15px;
}

.settings-add-bank-account .bank-icon-area {
  padding: 22px 25px;
  margin: 0 0 18px;
}

.settings-add-bank-account .bank-icon-img {
  width: 42px;
}

.settings-add-bank-account .bank-icon-area span {
  width: 30px;
}

.settings-add-bank-account .add-bank-account h3 {
  font-size: 17px;
  text-transform: uppercase;
}
