.main {
  background: #f3f5f7;
  padding-bottom: 50px;
  min-height: 100vh;
  padding-top: 24px;
}

.bank-account-list {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  margin: 0 0 20px;
}

.bank-icon-area {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(4, 4, 21, 0.16);
  border-radius: 50%;
  position: relative;
}

.bank-icon-area {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(4, 4, 21, 0.16);
  border-radius: 50%;
  position: relative;
}

.dashboard-header {
  z-index: 999;
  background-color: #f3f5f7;
  padding-top: 24px;
  position: sticky;
  top: -24px;
}
