.document-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 170px;
  border: 1px solid rgba(4, 4, 21, 0.2);
  border-radius: 12px;
  padding: 18px 26px;
}

.upload-icon {
  width: 16px;
}

.document-upload h4 {
  margin: 10px 0px;
  font-weight: 500;
  font-size: 18px;
  color: rgba(4, 4, 21, 0.8);
}

.document-upload p {
  width: 190px;
  text-align: center;
  line-height: 19px;
  margin: 0 0 20px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(4, 4, 21, 0.6);
}

.choose-file input::file-selector-button {
  cursor: pointer;
  background: #ffd89c;
  transition: 0.3s;
  color: rgba(4, 4, 21, 0.7);
  border: none;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 18px;
  border-radius: 26px;
}

.choose-file input::file-selector-button:hover {
  background: #fe9900;
}

.remove-btn.settings-btn {
  box-shadow: none;
  padding: 5px 6px;
  font-weight: 500;
}
